import React, { Component } from 'react';
// import { Line, Doughnut, Bar } from 'react-chartjs-2';
// import { Sparklines, SparklinesBars } from 'react-sparklines';
import { firebase } from '../../firebase';

// import DatePicker from 'react-datepicker';
// import { Dropdown } from 'react-bootstrap';

export class StudentScreen extends Component {

  constructor(props) {
    super();

    this.state = {
      // loading: false,
      studentData: null,
      studentId: null,
      drawData: null,
      // schoolId: '',
      numPages: 0,
      hasCover: false,
      totalPages: 0,
    };
  }

  async loadStudentData() {
    const { authData } = this.props;
      
    const userData = authData != null ? authData.userData : null;
    
    if (userData != null) {
      const schoolId = userData.key;
      const { studentId } = this.props.match.params;

      let numPages = this.state.numPages;
      
      if (userData["num_pag_desenho"] != null) {
        numPages = userData["num_pag_desenho"];
      }

      let hasCover = this.state.hasCover;

      if (userData["tem_capa_desenho"] != null) {
        hasCover = userData["tem_capa_desenho"];
      }

      let totalPages = numPages + (hasCover ? 1 : 0);

      const studentSnap = await firebase.db.ref(`escolas/${schoolId}/alunos/${studentId}`).once('value');
      const studentData = studentSnap.val();

      this.setState({studentId, studentData, numPages, hasCover, totalPages});

      // firebase.db.ref(`escolas/${this.state.schoolId}/alunos`).off();
      firebase.db.ref(`meusDesenhos/${studentId}`).off();

      firebase.db.ref(`meusDesenhos/${studentId}`)
        // .orderByChild('turma')
        // .equalTo(classId)
        .on('value', async snapshot => {
          const val = snapshot.val();

          // console.log('meus desenhos:');
          // console.log(val);

          const drawData = val;

          // let teacherName = null;

          // const studentSnap = await firebase.db.ref(`escolas/${schoolId}/alunos/${studentId}`).once('value');
          // const studentData = studentSnap.val();

          // if (profVal) {
          //   const profCode = Object.keys(profVal)[0];
            

          //   const profNameSnap = await firebase.db.ref(`escolas/${schoolId}/alunos/${profCode}`).once('value');
          //   const profNameVal = profNameSnap.val();

          //   if (profNameVal) {
          //     teacherName = profNameVal["nome"].split(' ')[0];
          //   } else {
          //     teacherName = null;
          //   }
          // } else {
          //   teacherName = null;
          // }

          // let listStudents = [];

          // if (val != null) {
          //   const keys = Object.keys(val);

          //   for (let i = 0; i < keys.length; i++) {
          //   // .forEach((k) => {
          //     const k = keys[i];
          //     const s = val[k];

          //     const studentData = {
          //       'codigo_aluno': s['codigo_aluno'],
          //       'nome': s['nome'],
          //       'turma': s['turma'],
          //       'num_paginas': s['num_paginas_desenho'] ? s['num_paginas_desenho'] : 0, //-1,
          //       'professor': teacherName,
          //     };

          //     // CONSULTA PELO NÚMERO DE PÁGINAS DIRETO NA HISTÓRIA
          //     // const storySnap = await firebase.db.ref(`/minhasHistorias/${s['codigo_aluno']}/slot0`).once('value');
          //     // const storyVal = storySnap.val();

          //     // // console.log(storyVal);

          //     // if (storyVal) {
          //     //   studentData['num_paginas'] = storyVal['totalDePaginas'];
          //     // } else {
          //     //   studentData['num_paginas'] = 0;
          //     // }

          //     // console.log(studentData);

          //     listStudents.push(studentData);
          //   };
          // }

          // listStudents.sort((a, b) => {
          //   return a['nome'].localeCompare(b['nome']);
          // });

          // console.log(listClass);

          // this.setState({loading: false, listStudents});
          this.setState({drawData});
        });
    }
  }

  componentDidMount() {
    this.loadStudentData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.loadStudentData();
    }
  }

  componentWillUnmount() {
    if (this.state.studentId != null) {
      firebase.db.ref(`meusDesenhos/${this.state.studentId}`).off();
    }
  }
  
  render () {
    const { classId } = this.props.match.params;

    // const {
    //   cover,
    //   page1,
    //   page2,
    //   page3,
    //   page4,
    //   page5,
    //   page6,
    //  } = this.state.drawData || {};

    const drawData = this.state.drawData || {};

    // if (this.state.loading) {
    //   return <Spinner />;
    // }

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
          {/* Turma {classId} */}
          Aluno(a) {this.state.studentData && this.state.studentData["nome"] ? this.state.studentData["nome"] : ""}
          </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Minhas Turmas</li>
              <li className="breadcrumb-item">
                {/* <a href="!#" onClick={event => event.preventDefault()}> */}
                <a href="!#" onClick={event => {
                  event.preventDefault(); this.props.history.push(`/class/${classId}`);
                }}>
                  {classId}
                </a></li>
              <li className="breadcrumb-item active" aria-current="page">
                {/* <a href="!#" onClick={event => event.preventDefault()}> */}
                <a href="!#" onClick={event => event.preventDefault()}>
                  {this.state.studentId}
                </a></li>
            </ol>
          </nav>
        </div>
        { this.state.hasCover &&
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 grid-margin stretch-card">
            <div className="card card-statistics">
              <a href="!#" onClick={event => {
                event.preventDefault(); this.props.history.push(`/class/${classId}/${this.state.studentId}/cover`);
              }}>
                <div className="card-body clickable">
                  <div className="d-flex flex-column">
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row flex-shrink-1">
                        <div className="p-0 flex-shrink-1">
                        {
                          drawData["cover"] && drawData["cover"].image != null && drawData["cover"].text != null && drawData["cover"].text !== '' ?
                          <i className="mdi mdi-check-circle text-success icon-lg"></i> :
                          <i className="mdi mdi-close-circle text-danger icon-lg"></i>
                        }
                        </div>
                        <div className="mt-2 w-100 align-self-center align-middle">
                            <h3 className="font-weight-medium text-center text-dark">Capa</h3>
                        </div>
                      </div>
                    </div>
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row justify-content-end">
                        <div className="p-0">
                        {
                          drawData["cover"] && drawData["cover"].image != null ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Imagem </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Imagem </p>
                        }
                        {
                          drawData["cover"] && drawData["cover"].text != null && drawData["cover"].text !== '' ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Texto </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Texto </p>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        }
        
        <div className="row d-flex flex-wrap">
          { Array.apply(null, Array(this.state.numPages)).map((v, np)=>
          <div key={np} className="col-xl-4 col-lg-4 col-md-4 col-sm-4 grid-margin stretch-card">
          <div className="card card-statistics">
              <a href="!#" onClick={event => {
                event.preventDefault(); this.props.history.push(`/class/${classId}/${this.state.studentId}/page${np+1}`);
              }}>
                <div className="card-body clickable">
                  <div className="d-flex flex-column">
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row flex-shrink-1">
                        <div className="p-0 flex-shrink-1">
                        {
                          drawData[`page${np+1}`] && drawData[`page${np+1}`].image != null && drawData[`page${np+1}`].text != null && drawData[`page${np+1}`].text !== '' ?
                          <i className="mdi mdi-check-circle text-success icon-lg"></i> :
                          <i className="mdi mdi-close-circle text-danger icon-lg"></i>
                        }
                        </div>
                        <div className="mt-2 w-100 align-self-center">
                            <h3 className="font-weight-medium text-center text-dark">Página {np+1}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row justify-content-end">
                        <div className="p-0">
                        {
                          drawData[`page${np+1}`] && drawData[`page${np+1}`].image != null ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Imagem </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Imagem </p>
                        }
                        {
                          drawData[`page${np+1}`] && drawData[`page${np+1}`].text != null && drawData[`page${np+1}`].text !== '' ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Texto </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Texto </p>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          )}
          {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 grid-margin stretch-card">
          <div className="card card-statistics">
              <a href="!#" onClick={event => {
                event.preventDefault(); this.props.history.push(`/class/${classId}/${this.state.studentId}/page2`);
              }}>
                <div className="card-body clickable">
                  <div className="d-flex flex-column">
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row flex-shrink-1">
                        <div className="p-0 flex-shrink-1">
                        {
                          page2 && page2.image != null && page2.text != null && page2.text !== '' ?
                          <i className="mdi mdi-check-circle text-success icon-lg"></i> :
                          <i className="mdi mdi-close-circle text-danger icon-lg"></i>
                        }
                        </div>
                        <div className="mt-2 w-100 align-self-center">
                            <h3 className="font-weight-medium text-center text-dark">Página 2</h3>
                        </div>
                      </div>
                    </div>
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row justify-content-end">
                        <div className="p-0">
                        {
                          page2 && page2.image != null ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Imagem </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Imagem </p>
                        }
                        {
                          page2 && page2.text != null && page2.text !== '' ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Texto </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Texto </p>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 grid-margin stretch-card">
          <div className="card card-statistics">
              <a href="!#" onClick={event => {
                event.preventDefault(); this.props.history.push(`/class/${classId}/${this.state.studentId}/page3`);
              }}>
                <div className="card-body clickable">
                  <div className="d-flex flex-column">
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row flex-shrink-1">
                        <div className="p-0 flex-shrink-1">
                        {
                          page3 && page3.image != null && page3.text != null && page3.text !== '' ?
                          <i className="mdi mdi-check-circle text-success icon-lg"></i> :
                          <i className="mdi mdi-close-circle text-danger icon-lg"></i>
                        }
                        </div>
                        <div className="mt-2 w-100 align-self-center">
                            <h3 className="font-weight-medium text-center text-dark">Página 3</h3>
                        </div>
                      </div>
                    </div>
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row justify-content-end">
                        <div className="p-0">
                        {
                          page3 && page3.image != null ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Imagem </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Imagem </p>
                        }
                        {
                          page3 && page3.text != null && page3.text !== '' ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Texto </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Texto </p>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div> */}
          </div>
          {/* <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 grid-margin stretch-card">
          <div className="card card-statistics">
              <a href="!#" onClick={event => {
                event.preventDefault(); this.props.history.push(`/class/${classId}/${this.state.studentId}/page4`);
              }}>
                <div className="card-body clickable">
                  <div className="d-flex flex-column">
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row flex-shrink-1">
                        <div className="p-0 flex-shrink-1">
                        {
                          page4 && page4.image != null && page4.text != null && page4.text !== '' ?
                          <i className="mdi mdi-check-circle text-success icon-lg"></i> :
                          <i className="mdi mdi-close-circle text-danger icon-lg"></i>
                        }
                        </div>
                        <div className="mt-2 w-100 align-self-center">
                            <h3 className="font-weight-medium text-center text-dark">Página 4</h3>
                        </div>
                      </div>
                    </div>
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row justify-content-end">
                        <div className="p-0">
                        {
                          page4 && page4.image != null ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Imagem </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Imagem </p>
                        }
                        {
                          page4 && page4.text != null && page4.text !== '' ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Texto </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Texto </p>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 grid-margin stretch-card">
          <div className="card card-statistics">
              <a href="!#" onClick={event => {
                event.preventDefault(); this.props.history.push(`/class/${classId}/${this.state.studentId}/page5`);
              }}>
                <div className="card-body clickable">
                  <div className="d-flex flex-column">
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row flex-shrink-1">
                        <div className="p-0 flex-shrink-1">
                        {
                          page5 && page5.image != null && page5.text != null && page5.text !== '' ?
                          <i className="mdi mdi-check-circle text-success icon-lg"></i> :
                          <i className="mdi mdi-close-circle text-danger icon-lg"></i>
                        }
                        </div>
                        <div className="mt-2 w-100 align-self-center">
                            <h3 className="font-weight-medium text-center text-dark">Página 5</h3>
                        </div>
                      </div>
                    </div>
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row justify-content-end">
                        <div className="p-0">
                        {
                          page5 && page5.image != null ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Imagem </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Imagem </p>
                        }
                        {
                          page5 && page5.text != null && page5.text !== '' ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Texto </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Texto </p>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 grid-margin stretch-card">
          <div className="card card-statistics">
              <a href="!#" onClick={event => {
                event.preventDefault(); this.props.history.push(`/class/${classId}/${this.state.studentId}/page6`);
              }}>
                <div className="card-body clickable">
                  <div className="d-flex flex-column">
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row flex-shrink-1">
                        <div className="p-0 flex-shrink-1">
                        {
                          page6 && page6.image != null && page6.text != null && page6.text !== '' ?
                          <i className="mdi mdi-check-circle text-success icon-lg"></i> :
                          <i className="mdi mdi-close-circle text-danger icon-lg"></i>
                        }
                        </div>
                        <div className="mt-2 w-100 align-self-center">
                            <h3 className="font-weight-medium text-center text-dark">Página 6</h3>
                        </div>
                      </div>
                    </div>
                    <div className="p-0 mt-0 mb-0">
                      <div className="d-flex flex-row justify-content-end">
                        <div className="p-0">
                        {
                          page6 && page6.image != null ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Imagem </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Imagem </p>
                        }
                        {
                          page6 && page6.text != null && page6.text !== '' ?
                          <p className="text-success mt-0 mb-0">
                          <i className="mdi mdi-check-circle mr-1" aria-hidden="true"></i> Texto </p> :
                          <p className="text-danger mt-0 mb-0">
                          <i className="mdi mdi-close-circle mr-1" aria-hidden="true"></i> Texto </p>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div> */}
      </div> 
    );
  }
}

export default StudentScreen;