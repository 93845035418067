import React, { Component } from 'react';
// import { Line, Doughnut, Bar } from 'react-chartjs-2';
// import { Sparklines, SparklinesBars } from 'react-sparklines';
// import { ProgressBar } from 'react-bootstrap'; // Dropdown

import { firebase } from '../../firebase';
// import Spinner from '../../shared/Spinner';

// import { generateStudentPdf, generateClassPdf } from '../../functions/GenerateTemplate';

// import DatePicker from 'react-datepicker';
// import { Dropdown } from 'react-bootstrap';
import ImageUploader from "react-images-upload";
import { Form } from 'react-bootstrap';

export class PageEditScreen extends Component {

  constructor(props) {
    super();

    this.state = {
      schoolId: null,
      studentData: null,
      studentId: null,
      drawData: null,
      textPage: null,
      imageUrlPage: null,
      picture: null,
      isUploading: false,
      numPages: 0,
      hasCover: false,
      totalPages: 0,
    };

    this.imageUploader = React.createRef();

    this.onDrop = this.onDrop.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveAndNext = this.onSaveAndNext.bind(this);
  }

  async loadStudentData() {
    const { authData } = this.props;
      
    const userData = authData != null ? authData.userData : null;
    
    if (userData != null) {
      const schoolId = userData.key;
      const { studentId, pageType } = this.props.match.params;

      let numPages = this.state.numPages;
      
      if (userData["num_pag_desenho"] != null) {
        numPages = userData["num_pag_desenho"];
      }

      let hasCover = this.state.hasCover;

      if (userData["tem_capa_desenho"] != null) {
        hasCover = userData["tem_capa_desenho"];
      }

      let totalPages = numPages + (hasCover ? 1 : 0);

      const studentSnap = await firebase.db.ref(`escolas/${schoolId}/alunos/${studentId}`).once('value');
      const studentData = studentSnap.val();

      this.setState({schoolId, studentId, studentData, numPages, hasCover, totalPages});

      // console.log(`meusDesenhos/${studentId}/${pageType}`);

      // firebase.db.ref(`meusDesenhos/${studentId}/${pageType}`).off();
      firebase.db.ref(`meusDesenhos/${studentId}`).off();

      // firebase.db.ref(`meusDesenhos/${studentId}/${pageType}`)
      firebase.db.ref(`meusDesenhos/${studentId}`)
        .on('value', async snapshot => {
          const val = snapshot.val();

          // console.log('meus desenhos:');
          // console.log(val);

          if (val != null && val[pageType] != null) {
            const drawData = val;

            this.setState({drawData, textPage: drawData[pageType].text, imageUrlPage: drawData[pageType].image});
          }
      });
    }
  }

  componentDidMount() {
    this.loadStudentData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // console.log("mount update");
      this.loadStudentData();
    }
  }

  componentWillUnmount() {
    if (this.state.studentId != null) {
      firebase.db.ref(`meusDesenhos/${this.state.studentId}`).off();
    }
  }

  onDrop (acceptedFiles) {
    // console.log(acceptedFiles);
    this.setState({
      picture: acceptedFiles.length > 0 ? acceptedFiles[0] : null,
    });
  }

  onChangeText(event) {
    this.setState({textPage: event.target.value});
  }

  onCancel(event) {
    const { classId } = this.props.match.params;

    event.preventDefault();
    this.props.history.push(`/class/${classId}/${this.state.studentId}`);
  }

  async savePage() {
    const { pageType } = this.props.match.params;

    // this.props.history.push(`/class/${classId}/${this.state.studentId}/${pageType}`);

    // let downloadURL = null;

    if (this.state.studentId != null) {
      let dataUpdate = {
        image: null,
        text: null
      };

      this.setState({isUploading: true});

      if (this.state.picture != null) {
        const extension = this.state.picture.name.split('.').pop();

        const uploadFileName = `${pageType}.${extension}`;

        // console.log(uploadFileName);

        const storageRef = firebase.storage.ref();
        const studentRef = storageRef.child(`${this.state.studentId}/${uploadFileName}`);

        // console.log("vou subir...");

        await studentRef.put(this.state.picture);

        // console.log("obter a url...");

        const downloadURL = await studentRef.getDownloadURL();

        // console.log(downloadURL);

        dataUpdate.image = downloadURL;
      } else {
        dataUpdate.image = this.state.imageUrlPage;
      }

      if (this.state.textPage != null) {
        dataUpdate.text = this.state.textPage;
      }

      if (dataUpdate.text === undefined) {
        dataUpdate.text = null;
      }

      if (dataUpdate.image === undefined) {
        dataUpdate.image = null;
      }

      if (dataUpdate.image != null || dataUpdate.text != null) {
        await firebase.db.ref(`meusDesenhos/${this.state.studentId}/${pageType}`).update(dataUpdate);


        if (this.state.schoolId != null && this.state.studentId != null && this.state.drawData != null) {
          // contar o nr de páginas finalizadas
          let countPages = 0;
          const keys = Object.keys(this.state.drawData);

          for (let i = 0; i < keys.length; i++) {
            const page = this.state.drawData[keys[i]];

            if (page != null && page.image != null && page.text != null && page.text.trim() !== "") {
              countPages++;
            } 
          }

          // setar na estrutura do aluno
          await firebase.db.ref(`escolas/${this.state.schoolId}/alunos/${this.state.studentId}`).update({
            'num_paginas_desenho': countPages,
          });
        }
      }

      this.setState({isUploading: false, picture: null});
    }
  }

  async onSave(event) {
    const { classId } = this.props.match.params;

    event.preventDefault();

    await this.savePage();

    this.setState({
      studentData: null,
      drawData: null,
      textPage: null,
      imageUrlPage: null,
      picture: null,
      isUploading: false,
    });

    this.imageUploader.current.clearPictures();

    this.props.history.push(`/class/${classId}/${this.state.studentId}`);
  }

  nextPageType() {
    const { pageType } = this.props.match.params;

    const pageNames = [
      'cover',
      'page1',
      'page2',
      'page3',
      'page4',
      'page5',
      'page6',
    ];

    const index = pageNames.indexOf(pageType);

    if (index < 0 || index === this.state.numPages) { // index === pageNames.length-1) {
      return null;
    } else {
      return pageNames[index+1];
    }

  }

  async onSaveAndNext(event) {
    const { classId } = this.props.match.params;

    event.preventDefault();

    await this.savePage();

    const nextPage = this.nextPageType();

    this.setState({
      studentData: null,
      drawData: null,
      textPage: null,
      imageUrlPage: null,
      picture: null,
      isUploading: false,
    });

    this.imageUploader.current.clearPictures();

    if (nextPage == null) {
      this.props.history.push(`/class/${classId}/${this.state.studentId}`);
    } else {
      this.props.history.push(`/class/${classId}/${this.state.studentId}/${nextPage}`);
    }
  }
  
  render () {
    const { classId, pageType } = this.props.match.params;

    // if (this.state.loading) {
    //   return <Spinner />;
    // }

    const mapPageName = { };

    if (this.state.hasCover) {
      mapPageName["cover"] = "Capa";
    }

    for (let i = 1; i <= this.state.numPages; i++) {
      mapPageName[`page${i}`] = `Página ${i}`;
    }

    // const { drawPageData } = this.state;

    // console.log(drawPageData);

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
          {/* Turma {classId} */}
          Aluno(a) {this.state.studentData && this.state.studentData["nome"] ? this.state.studentData["nome"] : ""}
          </h3>
          <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
              <li className="breadcrumb-item">Minhas Turmas</li>
              <li className="breadcrumb-item">
                {/* <a href="!#" onClick={event => event.preventDefault()}> */}
                <a href="!#" onClick={event => {
                  event.preventDefault(); this.props.history.push(`/class/${classId}`);
                }}>
                  {classId}
                </a></li>
                <li className="breadcrumb-item">
                {/* <a href="!#" onClick={event => event.preventDefault()}> */}
                <a href="!#" onClick={event => {
                  event.preventDefault(); this.props.history.push(`/class/${classId}/${this.state.studentId}`);
                }}>
                  {this.state.studentId}
                </a></li>
              <li className="breadcrumb-item active" aria-current="page">
                {/* <a href="!#" onClick={event => event.preventDefault()}> */}
                <a href="!#" onClick={event => event.preventDefault()}>
                  {mapPageName[pageType]}
                </a></li>
            </ol>
          </nav>
        </div>
        {
          this.state.isUploading &&
            <div className="overlay">
              <div className="spinner">
              </div>
            </div>
        }
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h2 className="card-title big-title">{mapPageName[pageType]}</h2>
                  <div className="mb-4 mt-5">
                    <div className="d-flex flex-row justify-content-end align-items-center">
                      <button type="button" className="btn btn-secondary ml-3" onClick={this.onCancel}>Cancelar</button>
                      <button type="button" className="btn btn-secondary ml-3" onClick={this.onSave}>Salvar</button>
                      <button type="button" className="btn btn-primary ml-3" onClick={this.onSaveAndNext}>Salvar e Continuar</button>
                    </div>
                  </div>
                </div>

                <div className="ml-3">
                  <Form.Group>
                    <label htmlFor="imgUploader">Imagem da página</label>
                    <ImageUploader
                      ref={this.imageUploader}
                      withIcon={false}
                      buttonText="Clique aqui para enviar uma imagem"
                      onChange={this.onDrop}
                      imgExtension={[".jpg", ".gif", ".png"]}
                      singleImage={true}
                      withLabel={true}
                      label={`Defina uma imagem para a ${mapPageName[pageType]}. Tamanho máximo do arquivo: 10 MB`}
                      maxFileSize={10485760}
                      fileSizeError="o tamanho do arquivo é muito grande"
                      fileTypeError="tipo do arquivo incorreto"
                      withPreview={true}
                      fileContainerStyle={{background: 'rgba(200, 200, 200, 0.2)'}}
                      // style={{background: 'red'}}
                    />
                  </Form.Group>
                </div>
                
                    <div className="d-flex flex-column justify-content-between align-items-center mb-4 mt-5">
                      { this.state.imageUrlPage &&
                      <div className="p-3 figure-border" style={{maxWidth: '60%'}}>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img className="p-0 align-self-center figure" style={{maxWidth: '100%'}} src={this.state.imageUrlPage} alt={`Imagem da ${mapPageName[pageType]}`} />
                        </div>
                      </div>
                      }
                      <div className="col-md-12 mt-5">
                      <Form.Group>
                        <label htmlFor="textPage">{pageType === "cover" ? "Título do Livro" : "Texto da página"}</label>
                        <textarea className="form-control"
                          id="textPage"
                          rows="10"
                          value={this.state.textPage || ''}
                          onChange={this.onChangeText}
                          maxLength={pageType === "cover" ? 60 : 250}
                        />
                      </Form.Group>
                      </div>
                    </div>
                
                
                {/* <div className="ml-3">
                    <h6 className="text-google font-weight-semibold mb-0">Dados sobre </h6>
                    <p className="text-muted card-text">You main list growing</p>
                  </div> */}
                <div className="mb-4 mt-5">
                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <button type="button" className="btn btn-secondary ml-3" onClick={this.onCancel}>Cancelar</button>
                    <button type="button" className="btn btn-secondary ml-3" onClick={this.onSave}>Salvar</button>
                    <button type="button" className="btn btn-primary ml-3" onClick={this.onSaveAndNext}>Salvar e Continuar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageEditScreen;